$dropdown-menu-min-width: 25rem;


#root {
    margin: 50px auto;
    width: 800px;
    height: 800px;
    min-width: 800px;
    min-height: 800px;
}

th,
td {
    width: 114px;
}

th {
    height: 20px;
}

td {
    position: relative;
    height: 80px;
    .event-data {
        position: absolute;
        bottom: 3px;
        right: 6px;
        font-size: 13px;
    }
}

.box {
    width: 250px;
    height: 70px;
}

.top-corner {
    position: absolute;
    top: 0;
    right: 0;
}

.time-capsule{
    background-color: #F9DCA5;
    
}

.checked {
    background: rgba(128, 128, 128, 0.664);
    width: 15px;
    border-radius: 50%;
    padding-left: 3px;
}